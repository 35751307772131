.contactWrap {
    .MuiContainer-maxWidthXl {
        padding: 0 12px;
    }

    .mainTitleWrap {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding-bottom: 10px;

        .mainTitle {
            h4 {
                color: #071942;
                //font-size: 26px;
                font-size: 1.625rem;
                // font-size: 1.625em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 1.425rem;
                }
            }

            h6 {
                color: rgba(7, 25, 66, 0.50);
                //font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.75rem;
                }
            }
        }
    }
.textfieldWrap{
  
}
  
   
}
.fileUploadSection {
    p{
        margin-bottom: 10px;
        font-weight: 600;
    }
    .closeBtn {
        position: absolute;
        right:0;
        top:70px;
    }
    .fileWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;

        svg {
            position: absolute;
            left: 0;
            right: 0;
            top: -55px;
            bottom: 0;
            margin: auto;
            z-index: 9999;
            width: 70px;
            height: 70px;
        }

        .uploadContainer {
            position: relative;
            width: 100%;
            display: flex;

            input[type='file'] {
                background-image: repeating-linear-gradient(-27deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px), repeating-linear-gradient(63deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px), repeating-linear-gradient(153deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px), repeating-linear-gradient(243deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px);
                background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
                background-position: 0 0, 0 0, 100% 0, 0 100%;
                background-repeat: no-repeat;
                padding: 7rem 5rem;
                text-align: center !important;
                background-color: #fff;
                font-size: 0;
                display: flex;
                flex: 1 1 auto;
                margin: 5px;

                &::file-selector-button {
                    display: none;
                    font-size: initial;
                }

                &:hover {
                    background: #f5f5f5;
                    background-image: repeating-linear-gradient(-27deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px), repeating-linear-gradient(63deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px), repeating-linear-gradient(153deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px), repeating-linear-gradient(243deg, #adadad, #adadad 4px, transparent 4px, transparent 14px, #adadad 14px);
                    background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
                    background-position: 0 0, 0 0, 100% 0, 0 100%;
                    background-repeat: no-repeat;
                }
            }

            &:after {
                content: "or drag and drop";
                width: 190px;
                height: 10%;
                overflow: auto;
                margin: auto;
                position: absolute;
                top: 0;
                left: 0;
                bottom: -45px;
                right: 0;
                font-size: 13px;
                text-align: right;
            }

            &:before {
                content: "Click to upload";
                width: 195px;
                height: 10%;
                overflow: auto;
                margin: auto;
                position: absolute;
                top: 0;
                left: 0;
                bottom: -45px;
                right: 0;
                font-size: 13px;
                color: #1A73E8;
                text-decoration: underline;
                text-align: left;
            }
        }

        span {
            position: relative;
            font-size: 13px;
            color: rgba(7, 25, 66, 0.3);
            top: -80px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.footerBtn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    Button {
     background-color: #F9BE02;
     text-transform: none;
     height: 40px;
     color: #071942;
     font-size: 0.875rem;
     line-height: 1.75;
     text-transform: capitalize;
     min-width: 64px;
     padding: 6px 16px;
     border-radius: 4px;
     font-weight: 600;
     &:hover {
        background-color: #fac51b;
     }
    }
}

.topWrap{
   &.contactBtn {
    Button {
        background-color: #F9BE02;
        text-transform: none;
        height: 40px;
        color: #071942;
        font-size: 0.875rem;
        line-height: 1.75;
        text-transform: capitalize;
        min-width: 64px;
        padding: 6px 16px;
        border-radius: 4px;
        font-weight: 600;
        width:104px !important;
        &:hover {
           background-color: #fac51b;
        }
    }
    }
}


.progresswrap {
    width: 100%;
    margin-top:140px;
  
    .progressInner {
      position: relative;
      background-image: repeating-linear-gradient(
          -27deg,
          #adadad,
          #adadad 4px,
          transparent 4px,
          transparent 14px,
          #adadad 14px
        ),
        repeating-linear-gradient(
          63deg,
          #adadad,
          #adadad 4px,
          transparent 4px,
          transparent 14px,
          #adadad 14px
        ),
        repeating-linear-gradient(
          153deg,
          #adadad,
          #adadad 4px,
          transparent 4px,
          transparent 14px,
          #adadad 14px
        ),
        repeating-linear-gradient(
          243deg,
          #adadad,
          #adadad 4px,
          transparent 4px,
          transparent 14px,
          #adadad 14px
        );
      background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
      background-position: 0 0, 0 0, 100% 0, 0 100%;
      background-repeat: no-repeat;
      margin: 20px 0;
      padding: 28px 20px;
      display: flex;
      flex-direction: column;
  
      .progressStatus {
        border-bottom: 1px dashed rgba(7, 25, 66, 0.2);
        padding: 0 0 20px 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
  
        &:last-child {
          border: none;
          padding: 0;
          margin: 0;
        }
  
        .progressDiv {
          display: flex;
          flex-direction: row;
          width: 100%;
          position: relative;
        }
  
        progress {
          -webkit-appearance: none;
          appearance: none;
          position: relative;
          flex: 1 1 0;
          height: 5px;
          font-size: 14px;
          top: 50%;
          left: 10px;
  
          &::-webkit-progress-bar {
            background: #cdcdcd52;
            border-radius: 10px;
            overflow: hidden;
          }
  
          &::-webkit-progress-value {
            background: #32c49e;
            transition: filter 300ms, width 300ms;
          }
  
          &::before {
            content: attr(title);
            position: absolute;
            left: 0;
            top: -20px;
            opacity: 0.4;
            color: rgba(7, 25, 66);
            font-size: 14px;
          }
  
          &::after {
            content: attr(value) "0%";
            color: rgba(7, 25, 66, 0.5);
            font-size: 12px;
            right: 0;
            top: -20px;
            position: absolute;
          }
        }
  
        .updatedIcon {
          display: flex;
          flex-direction: row;
          justify-content: end;
          align-items: center;
          margin-left: 20px;
  
          .updateStatus {
            flex-direction: row;
            align-items: center;
            display: flex;
            padding: 0 10px;
  
            &.downloadBtn {
              cursor: pointer;
            }
  
            svg {
              margin-right: 5px;
            }
  
            p {
              color: #071942;
              font-size: 13px;
            }
          }
        }
      }
  
      span.fileSize {
        position: absolute;
        left: 180px;
        font-size: 12px;
        color: rgba(7, 25, 66, 0.8);
        top: 42px;
      }
  
      .removeBtn {
        position: absolute;
        left: 45px;
        font-size: 12px;
        color: rgba(7, 25, 66, 0.5);
        bottom: -9px;
        cursor: pointer;
      }
    }
  }