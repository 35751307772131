html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  margin:0;
  padding:0;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  font-size: 100%;
}
body{
background-color: #f5f5f5 !important;
overflow: hidden;
}

.content{
  display:flex;
}

#vg-tooltip-element {
  font-size: 20px;
  color: #3e3c38;}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar-track{
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height:8px;
  background-color:transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  border:4px solid rgba(7,25 ,66, 0.6)
}