.templateWrap {
    .MuiContainer-maxWidthXl {
        padding: 0 12px;
    }

    .mainTitleWrap {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        padding-bottom: 10px;

        .mainTitle {
            h4 {
                color: #071942;
                //font-size: 26px;
                font-size: 1.625rem;
                // font-size: 1.625em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 1.425rem;
                }
            }

            h6 {
                color: rgba(7, 25, 66, 0.50);
                //font-size: 14px;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;

                @media only screen and (max-width: 1281px) {
                    font-size: 0.75rem;
                }
            }
        }
    }
.textfieldWrap{
  
}
  
   
}

.footerBtn{
    position: fixed;
    bottom: 20px;
    right: 20px;
    Button {
     background-color: #F9BE02;
     text-transform: none;
     height: 40px;
     color: #071942;
     font-size: 0.875rem;
     line-height: 1.75;
     text-transform: capitalize;
     min-width: 64px;
     padding: 6px 16px;
     border-radius: 4px;
     font-weight: 600;
     &:hover {
        background-color: #fac51b;
     }
    }
}

.topWrap{
   
    Button {
        background-color: #F9BE02;
        text-transform: none;
        height: 40px;
        color: #071942;
        font-size: 0.875rem;
        line-height: 1.75;
        text-transform: capitalize;
        min-width: 64px;
        padding: 6px 16px;
        border-radius: 4px;
        font-weight: 600;
        width:180px !important;
        &:hover {
           background-color: #fac51b;
        }
        svg {
            margin-right: 5px;
        }
    }
}
.textGridwrap {
    margin-top:90px;
   justify-content: center;
   align-items: center;
   flex-direction: column !important;
    p{
        margin-bottom: 20px;
        font-weight: 600;
    }
    .closeBtn {
        position: absolute;
        right:0;
        top:70px;
    }
}
.d-flex{
    display: flex;
}
.mb30 {
    margin-bottom: 20px;
}
.MuiSvgIcon-root.MuiSelect-icon {
    top: 5px !important;
}
.templateType {
    height: 40px;
    padding: 10px 0px !important;
}
.MuiFormLabel-root.MuiInputLabel-root {
    top: -2px;
    font-size: 16px !important;
    font-weight: 500 !important;
}
.templateTypes {
    .MuiFormLabel-root.MuiInputLabel-root {
    top: -2px;
    font-size: 13px !important;
    font-weight: 500 !important;
    }
}