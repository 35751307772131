.positionFixed {
  background: #071942;
}
.borderBottom {
 border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

  margin: 0 18px !important;
}
  .aibotClose {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    cursor: pointer;
  }

.headerWrap {
  display: flex;
  justify-content: space-between;
  background: #071942;

  .headerLeftwrap {
    display: flex;
  }

  .headerRight {
    display: flex;
    align-items: center;

    svg {
      display: flex;
      margin-right: 8px;
      cursor: pointer;
      margin-left: 0;
    }
  }
}

.menuWrap {
  background-color: transparent !important;
  .titleWrap {
    display: flex;
  }
  .settingWrap {
    display: flex;

    .imageWrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .textWrap {
      display: flex;
      flex-direction: column;
      p {
        font-size: 15px;
        color: #071942;
        margin: 0;
        font-weight: 600;
      }
      span {
        color: #071942;
        opacity: 60%;
        font-size: 13px;
      }
    }
  }
  &:hover {
    background-color: transparent !important;
  }
}
.listItemwrap {
  background-color: #fff;

  .MuiPaper-elevation {
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    .MuiAlert-icon {
      display: none;
    }

    .MuiAlert-action {
      position: absolute;
      right: 10px;
    }
  }

  .listItem {
    padding: 0;

    div {
      padding: 3px 5px;
      min-width: inherit;
    }

    .MuiListItemText-primary {
      //font-size: 14px;
      font-size: 0.875rem;
      color: rgba(7, 25, 66, 0.5);
    }
  }
}

.outerDrawer {
  position: relative;

  .arrows {
    position: absolute;
    right: -11px;
    z-index: 1202;
    bottom: 80px;
    border-radius: 50%;
    border: 1px solid #999;
    display: flex;
    cursor: pointer;
    background-color: #fff;
  }

  .MuiListItemButton-root {
    margin: 6px !important;
    border-radius: 6px;
    padding: 0.25rem 0.9rem;
    font-weight: 600 !important;
    min-height: 40px;
  }

  .MuiTypography-root {
    font-size: 0.875rem;
    white-space: nowrap;
    width: calc(100% - 0px);
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    display: inline-block;
    font-weight: 600;
  }

  .MuiListItemText-root {
    margin-left: -8px;
  }
}

mat-card-header.mat-card-header.ng-star-inserted {
  display: flex;
  padding-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 18px;

  .mat-card-avatar.header-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .mat-card-header-text {
    padding: 10px 14px;
    display: flex;
    flex-direction: column;

    .username {
      display: block;
      font-weight: 500;
      font-size: 0.938rem;
    }

    .orgname {
      font-size: 0.813rem;
    }
  }
}
